/**
* @description Reset css
* @author kygeng
* date: 2017-12-27
*/

*,
*::before,
*::after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
body,
html {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    background: #ffffff;
    word-wrap: break-word !important;
    word-break: break-word !important;
}
html body{font-family: "PingFangSC-Light","PingFangSC-normal", "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "\5FAE\8F6F\96C5\9ED1", Arial, sans-serif !important;}

blockquote,
body,
div,
a,
button,
dd,
dl,
dt,
fieldset,
form,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
input,
legend,
li,
ol,
p,
pre,
td,
textarea,
th,
ul {
//   word-break:break-all;
  margin: 0;
  padding: 0
}

body,
button,
input,
select,
textarea {
    color: #333333;
    font-size: 14px;
    line-height: 1 !important;
    font-family: "PingFangSC-Medium", "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif !important;
    -webkit-font-smoothing:antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: 100%
}

address,
cite,
dfn,
em,
var {
    font-style: normal
}

code,
kbd,
pre,
samp {
    font-family: courier new, courier, monospace
}

small {
    font-size: 12px
}

ol,
ul {
    list-style: none
}

a {
    text-decoration: none;
}

a:hover {
    text-decoration: none
}

sup {
    vertical-align: text-top
}

sub {
    vertical-align: text-bottom
}

legend {
    color: #000
}

fieldset,
img {
    border: 0
}

button,
input,
select,
textarea {
    font-size: 100%;
}

table {
    border-collapse: collapse;
    border-spacing: 0
}

/* 设置隐藏样式 */

.hidden {
    display: none !important;
}

/*设置文字无法复制*/

.noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

/*
 * 设置iconfont引用及样式
 */

/*
 * 设置iconfont引用及样式
 */
 @font-face {
    font-family: 'iconfont_notice';
    src: url('//at.alicdn.com/t/font_637380_80uuyxz3f168ncdi.eot');
    src: url('//at.alicdn.com/t/font_637380_80uuyxz3f168ncdi.eot?#iefix') format('embedded-opentype'), url('//at.alicdn.com/t/font_637380_80uuyxz3f168ncdi.woff') format('woff'), url('//at.alicdn.com/t/font_637380_80uuyxz3f168ncdi.ttf') format('truetype'), url('//at.alicdn.com/t/font_637380_80uuyxz3f168ncdi.svg#iconfont') format('svg');
}

.iconfont_notice {
    display: inline-block;
    margin: 0px 5px;
    vertical-align: middle;
    font-family: "iconfont_notice" !important;
    font-size: 14px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@font-face {
    font-family: 'iconfontCustom';
    /* project id 688065 */
    src: url('//at.alicdn.com/t/font_688065_h3ypa350wt8rggb9.eot');
    src: url('//at.alicdn.com/t/font_688065_h3ypa350wt8rggb9.eot?#iefix') format('embedded-opentype'), url('//at.alicdn.com/t/font_688065_h3ypa350wt8rggb9.woff') format('woff'), url('//at.alicdn.com/t/font_688065_h3ypa350wt8rggb9.ttf') format('truetype'), url('//at.alicdn.com/t/font_688065_h3ypa350wt8rggb9.svg#iconfont') format('svg');
}

.iconfont-custom {
    display: inline-block;
    margin: 0px 5px;
    vertical-align: middle;
    font-family: "iconfontCustom" !important;
    font-size: 14px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@font-face {
    font-family: 'iconfont-web';  /* project id 542707 */
    src: url('//at.alicdn.com/t/font_542707_8jzo4fidhoa.eot');
    src: url('//at.alicdn.com/t/font_542707_8jzo4fidhoa.eot?#iefix') format('embedded-opentype'),
    url('//at.alicdn.com/t/font_542707_8jzo4fidhoa.woff') format('woff'),
    url('//at.alicdn.com/t/font_542707_8jzo4fidhoa.ttf') format('truetype'),
    url('//at.alicdn.com/t/font_542707_8jzo4fidhoa.svg#iconfont') format('svg');
}

.iconfont-web {
    display: inline-block;
    margin: 0px 5px;
    vertical-align: middle;
    font-family: "iconfont-web" !important;
    font-size: 14px;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
/*
 * 设置滚动条样式 
 */

 :not(.disable-custom-scrollbar)::-webkit-scrollbar {
    width: 6px !important;
    height: 6px !important;
    -webkit-appearance: none;
}

:not(.disable-custom-scrollbar)::-webkit-scrollbar-thumb {
    height: 6px;
    border-radius: 6px;
    background-color: #BAC1C6;
}

:not(.disable-custom-scrollbar)::-webkit-scrollbar-track {
    border-radius: 0px;
}

/*clear chrome input yellow backgound color*/

input:-webkit-autofill {
    box-shadow: 0 0 0px 1000px white inset !important;
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}

#root {
    position: relative;
    width: 100%;
    height: 100%;
    >.App {
        position: relative;
        width: 100%;
        height: 100%;
    }
}