/**
 * Lines
 */
.layui-layer.layui-layer-page.line-scale {
    background-color: transparent;
    box-shadow: none;
}
@-webkit-keyframes line-scale {
    0% {
        -webkit-transform: scaley(1);
        transform: scaley(1);
    }

    50% {
        -webkit-transform: scaley(0.4);
        transform: scaley(0.4);
    }

    100% {
        -webkit-transform: scaley(1);
        transform: scaley(1);
    }
}
@keyframes line-scale {
    0% {
        -webkit-transform: scaley(1);
        transform: scaley(1);
    }

    50% {
        -webkit-transform: scaley(0.4);
        transform: scaley(0.4);
    }

    100% {
        -webkit-transform: scaley(1);
        transform: scaley(1);
    }
}

.line-scale > .layui-layer-content > div:nth-child(1) {
    -webkit-animation: line-scale 1s 0.1s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
    animation: line-scale 1s 0.1s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
}
.line-scale > .layui-layer-content > div:nth-child(2) {
    -webkit-animation: line-scale 1s 0.2s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
    animation: line-scale 1s 0.2s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
}
.line-scale > .layui-layer-content > div:nth-child(3) {
    -webkit-animation: line-scale 1s 0.3s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
    animation: line-scale 1s 0.3s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
}
.line-scale > .layui-layer-content > div:nth-child(4) {
    -webkit-animation: line-scale 1s 0.4s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
    animation: line-scale 1s 0.4s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
}
.line-scale > .layui-layer-content > div:nth-child(5) {
    -webkit-animation: line-scale 1s 0.5s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
    animation: line-scale 1s 0.5s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
}
.line-scale > .layui-layer-content > div {
    background-color: #d1d1d1;
    width: 2px;
    height: 25px;
    border-radius: 2px;
    margin: 2px;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    display: inline-block;
}
.layui-layer-content {
    text-align: center;
    margin-left: -10px;
}
.wechat-loading-wrapper p {
    color: #999;
    text-align: center;
    font-size: 12px;
}
